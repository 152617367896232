<template>
  <b-form>
    <div class="d-flex flex-row flex-wrap">
      <div
        v-if="!hiddenInput.province"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colProvice}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="province">{{
          labelProvince
        }}</label>

        <v-select
          id="province"
          v-model="province"
          :options="provinces"
          :disabled="disabledInput.province"
          :loading="isFetchingProvince"
          :clearable="province.id !== null"
          @input="onInput($event, 'province')"
        >
          <template #no-options>
            {{ `ไม่พบข้อมูล${labelProvince}` }}
          </template>
        </v-select>

        <small
          v-if="
            invalid &&
            validation.filterProvince.mProvinceId &&
            !validation.filterProvince.mProvinceId.required
          "
          class="text-danger"
        >
          กรุณาเลือก{{ labelProvince }}
        </small>
      </div>

      <div
        v-if="!hiddenInput.amphur"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colAmphur}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="amphur">{{
          labelAmphur
        }}</label>

        <v-select
          id="amphur"
          v-model="amphur"
          :options="amphurs"
          :disabled="disabledInput.amphur"
          :loading="isFetchingAmphur"
          :clearable="amphur.id !== null"
          @input="onInput($event, 'amphur')"
        >
          <template #no-options>
            {{ `ไม่พบข้อมูล${labelAmphur}` }}
          </template>
        </v-select>

        <small
          v-if="
            invalid &&
            validation.filterProvince.mAmphurId &&
            !validation.filterProvince.mAmphurId.required
          "
          class="text-danger"
        >
          กรุณาเลือก{{ labelAmphur }}
        </small>
      </div>

      <div
        v-if="!hiddenInput.school"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colSchool}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="school">{{
          labelSchool
        }}</label>

        <v-select
          id="school"
          v-model="school"
          :options="schools"
          :disabled="disabledInput.school"
          :loading="isFetchingSchool"
          :clearable="school.id !== null"
          @input="onInput($event, 'school')"
        >
          <template #no-options>
            {{ `ไม่พบข้อมูล${labelSchool}` }}
          </template>
        </v-select>

        <small
          v-if="
            invalid &&
            validation.filterMaster.mSchoolId &&
            !validation.filterMaster.mSchoolId.required
          "
          class="text-danger"
        >
          กรุณาเลือก{{ labelSchool }}
        </small>
      </div>
    </div>
  </b-form>
</template>

<script>
import { debounce } from "lodash";
import vSelect from "vue-select";
import { MAmphur, MProvince, MSchool } from "../../models";

export default {
  props: {
    formData: Object,
    hiddenInput: {
      type: Object,
      default: () => ({}),
    },
    mProvinceId: {
      type: [String, Number],
      default: null,
    },
    mAmphurId: {
      type: [String, Number],
      default: null,
    },
    mSchoolId: {
      type: [String, Number],
      default: null,
    },
    colProvice: {
      type: [String, Number],
      default: 4,
    },
    colAmphur: {
      type: [String, Number],
      default: 4,
    },
    colSchool: {
      type: [String, Number],
      default: 4,
    },
    labelProvince: {
      type: String,
      default: "จังหวัด",
    },
    labelAmphur: {
      type: String,
      default: "อำเภอ",
    },
    labelSchool: {
      type: String,
      default: "โรงเรียน",
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    defaultId: {
      type: [Number, String],
      default: null,
    },
    defaultLabel: {
      type: String,
      default: "ทั้งหมด",
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    "v-select": vSelect,
  },

  data() {
    return {
      isFetchingProvince: false,
      isFetchingAmphur: false,
      isFetchingSchool: false,
      isInitProvince: false,
      isInitAmphur: false,
      isInitSchool: false,
      isInit: false,
      province: { id: this.defaultId, label: this.defaultLabel },
      amphur: { id: this.defaultId, label: this.defaultLabel },
      school: { id: this.defaultId, label: this.defaultLabel },
      form: {
        mProvinceId: this.mProvinceId || null,
        mAmphurId: this.mAmphurId || null,
        mSchoolId: this.mSchoolId || null,
      },
      provinceAmphur: {
        province: {},
        amphur: {}
      }
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },

    province: {
      handler: "onProvinceChanged",
    },

    amphur: {
      handler: "onAmphurChanged",
    },

    provinceAmphur: {
      deep: true,
      handler: "onProvinceAmphurChanged",
    },

    school: {
      handler: "onSchoolChanged",
    },
  },

  computed: {
    provinces() {
      return [
        { id: this.defaultId, label: this.defaultLabel },
        ...MProvince.all().map((record) => ({
          id: record.id,
          label: record.m_province_name,
        })),
      ];
    },

    amphurs() {
      return this.province
        ? [
            { id: this.defaultId, label: this.defaultLabel },
            ...MAmphur.query()
              .where("mProvinceId", this.province.id)
              .get()
              .map((record) => ({
                id: record.id,
                label: record.m_amphur_name,
              })),
          ]
        : [];
    },

    schools() {
      const query = MSchool.query();

      if (this.province && this.province.id) {
        query.where("mProvinceId", this.province.id);
      }

      if (this.amphur && this.amphur.id) {
        query.where("mAmphurId", this.amphur.id);
      }

      let data = query.get();

      if (data && data.length) {
        data = [
          { id: this.defaultId, label: this.defaultLabel },
          ...data.map((record) => ({
            id: record.id,
            label: record.m_school_name,
          })),
        ];
      }

      return data;
    },
  },

  methods: {
    async init() {
      let mProvince, mAmphur;

      await this.fetchProvince();

      if (this.mProvinceId && !this.isInitProvince) {
        mProvince = MProvince.find(this.mProvinceId);

        this.isInitProvince = true;
      }

      if (mProvince) {
        this.province = {
          id: mProvince.id,
          label: mProvince.m_province_name,
        };

        await this.fetchAmphur(mProvince);

        if (this.mAmphurId && !this.isInitAmphur) {
          mAmphur = MAmphur.find(this.mAmphurId);

          this.isInitAmphur = true;
        }
      } else {
        this.province = { id: this.defaultId, label: this.defaultLabel };
      }

      if (mAmphur) {
        this.amphur = {
          id: mAmphur.id,
          label: mAmphur.m_amphur_name,
        };
      } else {
        this.amphur = { id: this.defaultId, label: this.defaultLabel };
      }

      if (mProvince && mAmphur) {
        this.provinceAmphur = {
          province: mProvince,
          amphur: mAmphur
        };
      }

      this.isInit = true;
    },

    async fetchProvince() {
      this.isFetchingProvince = true;

      try {
        await MProvince.api().findAll();
      } catch (error) {
        console.error(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลจังหวัดได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingProvince = false;
      }
    },

    async onProvinceChanged(province) {
      let id = null;

      if (province && province.id) {
        id = province.id;
        
        await this.fetchAmphur(province);
      }

      this.$set(this.form, "mProvinceId", id);

      // console.log(this.isInit);

      if (this.isInit) {
        if (
          this.provinceAmphur.province &&
          this.provinceAmphur.province.id &&
          this.provinceAmphur.province.id !== province.id
        ) {
          this.amphur = { id: this.defaultId, label: this.defaultLabel };
        }

        this.$set(this.provinceAmphur, "province", { ...province });
      }
    },

    async fetchAmphur(province) {
      this.isFetchingAmphur = true;

      try {
        await MAmphur.api().findAll({
          mProvinceId: province.id,
        });
      } catch (error) {
        console.error(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลจังหวัดได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingAmphur = false;
      }
    },

    async onAmphurChanged(amphur) {
      let id = null;

      if (amphur && amphur.id) {
        id = amphur.id;
      }

      this.$set(this.form, "mAmphurId", id);
      // console.log(this.isInit);
      if (this.isInit) {
        this.$set(this.provinceAmphur, "amphur", { ...amphur });
      }
    },

    async onSchoolChanged(school = null) {
      // console.log(school);
      let id = null;

      if (school && school.id) {
        id = school.id;
      }

      this.$set(this.form, "mSchoolId", id);
    },

    async onProvinceAmphurChanged(provinceAmphur) {
      this.handleProvinceAmphurChanged(provinceAmphur);
    },

    async handleProvinceAmphurChanged(provinceAmphur = {}) {
      if (this.hiddenInput && this.hiddenInput.school) return;

      // console.log(provinceAmphur);

      const params = {};

      MSchool.deleteAll();

      const { province = {}, amphur = {} } = provinceAmphur;

      if (!province.id) {
        return;
      }

      if (province && province.id) {
        this.$set(params, "mProvinceId", province.id);
      }

      if (amphur && amphur.id) {
        this.$set(params, "mAmphurId", amphur.id);
      }

      await this.fetchSchool(params);

      if (this.mSchoolId && !this.isInitSchool) {
        const mSchool = MSchool.find(this.mSchoolId);

        if (mSchool) {
          this.school = {
            id: mSchool.id,
            label: mSchool.m_school_name,
          };
        }

        this.isInitSchool = true;
      } else {
        this.school = { id: this.defaultId, label: this.defaultLabel };
      }
    },

    async fetchSchool(params = {}) {
      let promise;

      this.isFetchingSchool = true;

      try {
        promise = await MSchool.api().findAll(params);
      } catch (error) {
        console.error(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลโรงเรียนได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingSchool = false;
      }

      return promise;
    },

    syncData(v) {
      this.$emit("change", v);
    },

    onInput(value, dataName) {
      if (!value) {
        this[dataName] = { id: this.defaultId, label: this.defaultLabel };
      }
    },
  },

  mounted() {
    this.handleProvinceAmphurChanged = debounce(
      this.handleProvinceAmphurChanged,
      500
    );

    this.init();
  },
};
</script>
