<template>
  <div class="row">
    <div class="col-12 col-md-7 col-xl-7">
      <div>
        <ol class="breadcrumb text-uppercase font-size-xs p-0">
          <template v-for="(breadcrumb, index) in breadcrumbs">
            <li
              v-if="breadcrumb.active"
              :key="`breadcrumb--active-${index}`"
              class="breadcrumb-item active"
              aria-current="page"
            >
              {{ heading }}
            </li>

            <li v-else :key="`breadcrumb-${index}`" class="breadcrumb-item">
              <router-link :to="breadcrumb.to">{{
                breadcrumb.text
              }}</router-link>
            </li>
          </template>
        </ol>
        <h5 class="display-4 mt-1 mb-2 font-weight-bold">{{ heading }}</h5>
        <p class="text-black-50 mb-0">{{ subheading }}</p>
      </div>
    </div>

    <div
      class="
        col-12
        col-md-5
        col-xl-5
        d-flex
        align-items-center
        justify-content-start
        mt-4 mt-md-0
        justify-content-md-end
      "
    >
      <slot name="right" />
    </div>
    <div class="col-12">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: Array,
    heading: String,
    subheading: String,
  },
};
</script>
