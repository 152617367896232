<template>
  <div
    class="
      text-center
      d-flex
      flex-column
      align-items-center
      justify-content-center
    "
  >
    <b-spinner label="loading" class="mb-2"></b-spinner>
    <div v-text="message"></div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "Loading...",
    },
  },
};
</script>
