<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <div class="col-12 px-0">
        <filter-master
          v-model="filterMaster"
          :m-headquarter-id="
            editData && editData.mHeadquarterId
              ? editData.mHeadquarterId
              : !authUser.canAccessAllHeadQuarters
              ? authUser.mHeadquarterId
              : null
          "
          :m-division-id="
            editData && editData.mDivisionId
              ? editData.mDivisionId
              : !authUser.canAccessAllDivisions
              ? authUser.mDivisionId
              : null
          "
          :m-station-id="
            editData && editData.mStationId
              ? editData.mStationId
              : !authUser.canAccessAllStations
              ? authUser.mStationId
              : null
          "
          :disabled-input="disabledInput"
          label-headquarter="กองบัญชาการ"
          label-division="กองบังคับการ"
          label-station="กองกำกับการ"
          default-label="-"
          :hidden-input="{ school: true, schoolClass: true, room: true }"
          :invalid="invalid"
          :validation="validation"
        >
        </filter-master>
      </div>

      <div class="col-12 px-0">
        <filter-province
          v-model="filterProvince"
          :m-province-id="filterProvince.mProvinceId"
          :m-amphur-id="filterProvince.mAmphurId"
          :disabled-input="disabledInput"
          :hidden-input="{ school: true }"
          default-label="-"
          :invalid="invalid"
          :validation="validation"
        >
        </filter-province>
      </div>

      <div class="col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">ชื่อโรงเรียน</label>

        <b-form-input
          id="lastName"
          :state="invalid ? validation.form.m_school_name.required : null"
          type="text"
          :disabled="disabled"
          v-model="form.m_school_name"
        ></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกชื่อโรงเรียน
        </b-form-invalid-feedback>
      </div>
    </div>
  </b-form>
</template>

<script>
import { Auth } from "../../models";
import FilterMaster from "./FilterMaster";
import FilterProvince from "./FilterProvince";

export default {
  props: {
    formData: Object,
    editData: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    FilterMaster,
    FilterProvince,
  },

  data() {
    return {
      form: {
        m_school_name: this.editData.m_school_name || "",
      },
      filterMaster: {
        mHeadquarterId: this.editData.mHeadquarterId || null,
        mDivisionId: this.editData.mDivisionId || null,
        mStationId: this.editData.mStationId || null,
      },
      filterProvince: {
        mProvinceId: this.editData.mProvinceId || null,
        mAmphurId: this.editData.mAmphurId || null,
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },
    filterMaster: {
      deep: true,
      immediate: true,
      handler: "syncFilterMaster",
    },
    filterProvince: {
      deep: true,
      immediate: true,
      handler: "syncFilterProvince",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    async init() {
      //
    },

    syncData(v) {
      this.$emit("change", v);
    },

    syncFilterMaster(v) {
      this.$emit("update:filterMaster", v);
    },

    syncFilterProvince(v) {
      this.$emit("update:filterProvince", v);
    },
  },

  mounted() {
    this.init();
  },
};
</script>
